import * as React from "react"
import * as Component from '../components'

// markup
const IndexPage = () => {
  return (
    <Component.Layout className="page-index" >
        <Component.Mainframe />
        <Component.Sectionone />
    </Component.Layout>
  )
}

export default IndexPage
